/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import forecastPerformanceDashboardActions from '../../../actions/forecastPerformanceDashboardActions';
import './demandAndSupply.scss';
import { connect } from 'react-redux';
import { TimeFilterEnum } from '../Common/Enums/TimeFilterEnum';
import { months } from '../DemandAndSupply/CarouselCharts/CommonCarouselFunctions';
import MapeYearlyCarouselChart from './CarouselCharts/MapeYearlyCarouselChart';
import MapeByCategoryCarouselChart from './CarouselCharts/MapeByCategoryCarouselChart';
import MapeByTopTenItemsCarouselChart from './CarouselCharts/MapeByTopTenItemsCarouselChart';

const DemandAndSupply = (props) => {
  const [loading, setLoading] = useState(true);
  const marketIds = props.mbuData.map(market => market.marketId);

  useEffect(() => {
    props.getMapeChartData(marketIds);
    setLoading(true); 
    
    const timer = setTimeout(() => {
      props.getMapeByCategoryChartData(TimeFilterEnum.YTD, [], marketIds, null);
      props.getMapeByTopTenItemsChartData(TimeFilterEnum.YTD, [], marketIds);
      setLoading(false); 
    }, 2000); 
  
    return () => clearTimeout(timer); 
  }, []);
  
  
  const getYearlyMapeData = (markets) => {
    props.getMapeChartData(markets);
  };

  const getMapeByCategoryData = (categoryTimeFilter, selectectedMonths, mbuForCategoryIds, categoryId) => {
    props.getMapeByCategoryChartData(categoryTimeFilter, selectectedMonths, mbuForCategoryIds, categoryId);
  };

  const getMapeByTopTenItemsData = (topTenItemsTimeFilter, selectectedMonths, mbuForTop10ItemsId) => {
    props.getMapeByTopTenItemsChartData(topTenItemsTimeFilter,selectectedMonths, mbuForTop10ItemsId); 
  };

  const handleExportSummary = (exportChart, periodFilter, selectectedMonths, selectectedMarkets, categoryId) => {
    props.exportRawData(exportChart, periodFilter, selectectedMonths, selectectedMarkets, categoryId);
  };

  let isValid = props.mapeYearlyChartData !== undefined &&  props.mapeByCategoryChartData !== undefined 
  && props.mapeByTopTenItemsChartData !== undefined;

  return (
    <div className='carousel-container'>
      {loading || !isValid && (<div className="app-spinner">
        <span className="spinner" />
      </div>)}

      {!loading  && isValid && (  
        <Carousel
          showThumbs={true}
          showStatus={true}
          emulateTouch={false}
          infiniteLoop={true}
          selectedItem={0} 
          centerMode={false}
        >
          <MapeYearlyCarouselChart  mbus={props.mbuData} 
            chartData={props.mapeYearlyChartData}
            getYearlyMapeData={getYearlyMapeData}
            handleExportSummary = {handleExportSummary}
            months= {months} />

          <MapeByCategoryCarouselChart  mbus={props.mbuData} 
            productCategories={props.productCategoriesData}
            chartData={props.mapeByCategoryChartData}
            getMapeByCategoryData={getMapeByCategoryData}
            handleExportSummary = {handleExportSummary}
            months= {months} />

          <MapeByTopTenItemsCarouselChart  mbus={props.mbuData} 
            chartData={props.mapeByTopTenItemsChartData}
            getMapeByTopTenItemsData={getMapeByTopTenItemsData}
            handleExportSummary = {handleExportSummary}
          />
        </Carousel>)}
  
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    mapeYearlyChartData: state.forecastPerformanceDashboard.mapeYearlyChartData,
    mapeByCategoryChartData: state.forecastPerformanceDashboard.mapeByCategoryChartData,
    mapeByTopTenItemsChartData: state.forecastPerformanceDashboard.mapeByTopTenItemsChartData,
  };
};
 

const mapDispatchToProps = (dispatch) => {
  return {
    getMapeChartData: (selectedMarkets) => dispatch(forecastPerformanceDashboardActions.getMapeChartData(selectedMarkets)),

    getMapeByCategoryChartData: (categoryTimeFilter, selectedMonths, selectedMarkets, categoryId) => 
      dispatch(forecastPerformanceDashboardActions.getMapeByCategoryChartData(categoryTimeFilter, selectedMonths, selectedMarkets, categoryId)),

    getMapeByTopTenItemsChartData: (topTenItemsTimeFilter,  selectedMonths, selectedMarkets) => 
      dispatch(forecastPerformanceDashboardActions.getMapeByTopTenItemsChartData(topTenItemsTimeFilter,  selectedMonths, selectedMarkets)),

    exportRawData: (exportChart, timeFilter, selectedMonths, selectedMarkets, categoryId) => 
      dispatch(forecastPerformanceDashboardActions.exportRawData(exportChart, timeFilter, selectedMonths, selectedMarkets, categoryId)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(DemandAndSupply);