/* eslint-disable react/jsx-key */
import React from 'react';
import { TimeFilterEnum } from '../../Common/Enums/TimeFilterEnum';
import DropDownSelect from '../../Common/DropdownSelect';
import MultiSelect from '../../../siteID/siteIDApplications/list/MultiSelect';
import './PeriodMultiSelectFilter.scss'; 

function PeriodMultiSelectFilter({ periodFilter, selectedMonths, onPeriodFilterChange, onSelectedMonths, orderMetrics }) {
  const handleChange = (e) => {
    const newFilterValue = e.target.value;
    onPeriodFilterChange(newFilterValue);
  };
  
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  
  const periods = [
    {
      Id: TimeFilterEnum.YTD,
      Name: currentYear.toString(),
    },
    {
      Id: TimeFilterEnum.PY,
      Name: previousYear.toString(),
    },
  ];
  
  const months = [
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
  ];

  const toggleMonthsSelection = (months) => {
    onSelectedMonths(months);
  };

  return (
    <>  
      <div className='period-filter-container'>
        <div className='multi-select-siteid width-style'
          style={{ marginRight: '50px' }}>
          <DropDownSelect
            options={periods}
            selectedValue={periodFilter}
            hasAllFilter={false}
            onChange={handleChange} /> 
        </div>
        <div style={{ marginRight: '50px', flexGrow: 0 }}>
          <MultiSelect
            title="Months"
            selectedValues={selectedMonths}
            valueChanged={toggleMonthsSelection}
            values={months}
            valueName="selectedMonths"
          />
        </div>
      </div>
    </>
  );
}

export default PeriodMultiSelectFilter;
